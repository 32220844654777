<template>
    <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isPhoneLoading }'>
        <div class="kt-input-icon kt-input-icon--left ">
            <span class="kt-input-icon__icon kt-input-icon__icon--left">
                <span style="padding-bottom: 1px;">
                    +62
                </span>
            </span>
            <input
                @input="changedData"
                type="text"
                class="form-control"
                v-model="phoneNumber"
                placeholder="Nomor Telepon"
                v-validate="rules"
                data-vv-validate-on="blur"
                data-vv-as="Nomor Telepon"
                :name="name"
                :class="{'is-invalid': errors.has(name) }"
                :data-vv-scope="(scope != null) ? scope : null"
                :disabled="isDisabled"
            />
        </div>
        <small
            v-show="errors.first(name)"
            class="text text-danger"
            >{{ errors.first(name) }}</small>
    </div>
</template>

<script>
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");

VeeValidate.setMode('passive');
export default {
    inject: ["$validator"],
    props: {
        data: { type: String },
        isValidate: { type: Boolean },
        isDisabled: { type: Boolean, default: false},
        scope: {type: String, default: null},
        oldPhoneNum: {type: String},
        editMode: {type: Boolean, default: false},
        phonePatient: {type: Boolean, default: false},
        isRepeater: { type: Boolean, default: false},
        index: {type: Number}
    },
    data() {
        return {
            name: "PhoneNumber",
            phoneNumber: null,
            oldNumber: null,
            isPhoneLoading: false,
            rules: { required: false, numeric:true, regex: /^[0-9]{8,16}$/, uniquePhone: false },
            abortController: null,
        }
    },
    watch: {
        data: function(val){
            this.phoneNumber = (val != null) ? this.editPhone(val) : null;
        },
        oldPhoneNum: function(val){
            this.oldNumber = (val != null) ? this.editPhone(val) : null;
        },
    },
    methods: {
        editPhone: function(data){
            if(data[0] == '6'){
                return data.substr(2, data.length-1);
            } else {
                return data.substr(1, data.length-1);
            }
        },
        changedData: function() {
            this.$emit("update:changedValue", '62'+this.phoneNumber);
        },
        checkPhone: function(value, resolve){
            var params = 'mobilePhone1:62'+value;
            if(this.isValidate) {
                if (this.abortController) {
                    this.abortController.abort()
                }
                const repo = PatientRepository.search({query:params})
                this.abortController = repo.next().value
                const reposi = repo.next().value
                repo.next()
                reposi.then((response) => {
                    this.isPhoneLoading = false;
                    if (response.data.length > 0) {
                        return resolve({
                            valid: false,
                            data: {
                            message: `Nomor telepon +62${value} sudah terdaftar`
                            }
                        });
                    } else {
                        return resolve({
                            valid: true
                        });
                    }
                }).catch(err => {
                    this.isPhoneLoading = false;
                    const msg = (err.message) ? err.message : err;
                    this.showToastError('check telepon : ' + msg);
                    return resolve({
                        valid: false,
                        data : {
                            message: `Gagal Validasi Check Nomor Telepon`
                        }
                    })
                })
            } else {
                this.isPhoneLoading = false;
                return resolve({
                    valid: true
                });
            }
            
            
        }
    },
    created() {
        this.phoneNumber = (this.data != null) ? this.editPhone(this.data) : null;
        this.oldNumber = (this.oldPhoneNum != null) ? this.editPhone(this.oldPhoneNum) : null;

        this.rules.required = this.isValidate
        this.rules.uniquePhone = this.phonePatient
        if(this.scope != null){
            this.name = this.scope+'.'+this.name;
        }
        if(this.isRepeater){
            this.name = this.name+'-'+this.index;
        }
    },
    mounted() {
        const isPhoneUnique = value =>
        new Promise(resolve => {
            this.errors.remove(this.name);
            this.isPhoneLoading = true
            if(this.editMode){
                if(value == this.oldNumber) {
                    this.isPhoneLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkPhone(value, resolve);
                }
            } else {
                this.checkPhone(value, resolve);
            }
        });
        Validator.extend("uniquePhone", {
            validate: isPhoneUnique,
            getMessage: (field, params, data) => data.message
        });
    },
}
</script>

<style>

</style>
