export default function Perujuk() {
    return {
        id: null,
        userId: null,
        firstName: null,
        lastName: null,
        alamatPerujuks: [],
        teleponPerujuks: [],
        email: null,
        kategoriId: null,
        status: "AKTIF"
    };
}
