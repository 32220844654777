<template>
    <div class="row" style="margin-bottom: 1rem">
        <div class="col-5">
            <input
                v-if="data.tipe == TipeTelepon.HP_PRIMARY"
                type="text"
                class="form-control"
                :disabled="true"
                value="Nomor HandPhone Utama">
            <select
                v-else
                v-model="data.tipe"
                class="form-control"
                v-validate="'required'"
                :name="'selectedTipeTelp'+index"
                data-vv-as="Tipe Telepon"
                :class="{'is-invalid': errors.has('selectedTipeTelp'+index) }"
            >
                <option :value="null" disabled selected >Pilih Tipe Telepon</option>
                <option :value="TipeTelepon.HP">Handphone</option>
                <option :value="TipeTelepon.KERJA">Kantor</option>
                <option :value="TipeTelepon.RUMAH">Rumah</option>
                <option :value="TipeTelepon.LAINNYA">Lainnya</option>
            </select>
            <div v-show="errors.first('selectedTipeTelp'+index)" class="invalid-feedback">{{ errors.first('selectedTipeTelp'+index) }}</div>
        </div>
        <div class="col-5">
            <phone-number
                :data="data.nomor"
                :is-validate="true"
                :changedValue.sync="data.nomor"
                :is-repeater="true"
                :index="index"
            >
            </phone-number>
        </div>
        <div class="col-2" v-if="data.tipe != TipeTelepon.HP_PRIMARY">
            <button type="button" class="btn btn-elevate-hover btn-sm btn-font-danger" @click="deleteComponent">
                <i class="la la-close"></i>
            </button>
        </div>
    </div>
</template>

<script>
import TipeTelepon from "./../../../constants/tipe-telepon-enum";
import PhoneNumber from "./../../_general/PhoneNumber";
export default {
    inject: ["$validator"],
    components:{
        PhoneNumber
    },
    provide() {
        return {
        $validator: this.$validator
        };
    },
    props:{
        data: { type: Object },
        index: {type: Number}
    },
    data() {
        return {
            TipeTelepon: TipeTelepon
        }
    },
    methods: {
        deleteComponent: function(){
            this.$emit('deltelp', this.index )
        }
    },
}
</script>

<style>

</style>
