<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Perujuk</h5>
            </div>
            <form @submit.prevent="submitData()">
            <div class="modal-body">
                <input
                    type="hidden"
                    class="form-control"
                    v-model.number="perujuk.id"
                />
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Nama Depan:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nama Depan"
                            v-model="perujuk.firstName"
                            v-validate="'required'"
                            data-vv-as="Nama Depan"
                            name="namaDepan"
                            :class="{'is-invalid': errors.has('namaDepan') }"/>
                        <div v-show="errors.first('namaDepan')" class="invalid-feedback">{{ errors.first('namaDepan') }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Nama Belakang:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Nama Belakang"
                            v-model="perujuk.lastName"
                            v-validate="'required'"
                            data-vv-as="Nama Belakang"
                            name="namaBelakang"
                            :class="{'is-invalid': errors.has('namaBelakang') }"/>
                        <div v-show="errors.first('namaBelakang')" class="invalid-feedback">{{ errors.first('namaBelakang') }}</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Kategori:</label>
                    <div class="col-lg-6">
                        <kategori-select
                            v-model="registrasi.kategoriId"
                            :validation="'required'"
                            :value-label="'id'"
                        ></kategori-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Email:</label>
                    <div class="col-lg-6">
                        <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                            v-model="perujuk.email"
                            v-validate="'required|email'"
                            :disabled="perujuk.id"
                            data-vv-as="Email"
                            name="email"
                            :class="{'is-invalid': errors.has('email') }"/>
                        <div v-show="errors.first('email')" class="invalid-feedback">{{ errors.first('email') }}</div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-lg-3 col-form-label">Telepon:</label>
                    <div class="col-lg-8">
                        <telepon-repeater v-for="(item, i) in perujuk.teleponPerujuks"
                            :key="'telp'+i"
                            :data="item"
                            :index="i"
                            @deltelp="deleteTelp" >
                        </telepon-repeater>
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-info btn-sm" @click="pushTelepon">
                                    <i class="la la-plus"></i> Tambah Telepon
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-lg-3 col-form-label">Alamat:</label>
                    <div class="col-lg-8">
                        <alamat-repeater v-for="(item, j) in perujuk.alamatPerujuks"
                            :key="'alm'+j"
                            :data="item"
                            :index="j"
                            @delalamat="deleteAlmt"
                        >
                        </alamat-repeater>
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-info btn-sm" @click="pushAlamat">
                                    <i class="la la-plus"></i> Tambah Alamat
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="submit" class="btn btn-default btn-bold btn-upper btn-font-md">Save</button>
            </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import Perujuk from "../../../model/perujuk-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const PerujukRepository = RepositoryFactory.get("perujuk");
import KategoriSelect from "./../../_select/Kategori";
import TipeTelepon from "./../../../constants/tipe-telepon-enum";
import TeleponRepeater from "./../_repeaters/TeleponRepeater";
import AlamatRepeater from "./../_repeaters/AlamatRepeater";
export default {
  components: {
    KategoriSelect,
    TeleponRepeater,
    AlamatRepeater
  },
  data() {
    return {
      perujuk: new Perujuk()
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  methods: {
    constructData: function() {
      this.perujuk.teleponPerujuks.push({
        nomor: null,
        tipe: TipeTelepon.HP_PRIMARY
      });
      this.perujuk.alamatPerujuks.push({
        nama: null,
        tipe: null,
        jalan: null
      });
    },
    deleteAlmt: function(index) {
      this.perujuk.alamatPerujuks.splice(index, 1);
    },
    deleteTelp: function(index) {
      this.perujuk.teleponPerujuks.splice(index, 1);
    },
    pushAlamat: function() {
      this.perujuk.alamatPerujuks.push({
        nama: null,
        tipe: null,
        jalan: null
      });
    },
    pushTelepon: function() {
      this.perujuk.teleponPerujuks.push({
        nomor: null,
        tipe: null
      });
    },
    clearData: function() {
      $("#" + this.modalName).modal("hide");
      this.$emit("update:showModal", false);
    },
    updateData: function(data) {
      this.perujuk = Object.assign({}, data);
      const index = this.perujuk.teleponPerujuks.findIndex(x => x.tipe == TipeTelepon.HP_PRIMARY);
      if(index > -1){
          const temp = this.perujuk.teleponPerujuks[index];
          this.deleteTelp(index);
          this.perujuk.teleponPerujuks.unshift(temp);
      }
    },
    submitData: function() {
      var vx = this;

      this.$validator.validateAll().then(result => {
        if (result) {
            if(this.perujuk.id){
                let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Perubahan data Perujuk akan tersimpan.",
                swalType: "info",
                onConfirmButton: function() {
                    blockUI.blockModal();
                    vx.update(vx.perujuk);
                },
                onCancelButton: function() {
                    blockUI.unblockModal();
                }
                });
            } else {
                let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Penambahan data Perujuk akan tersimpan.",
                swalType: "info",
                onConfirmButton: function() {
                    blockUI.blockModal();
                    vx.create(vx.perujuk);
                },
                onCancelButton: function() {
                    blockUI.unblockModal();
                }
                });
            }
            return;

        }
      });
    },
    async update(payload) {
      var vx = this;

      await PerujukRepository.updatePerujuks(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then(response => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan data Perujuk berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function() {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Perubahan data Perujuk gagal disimpan.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockModal();
            }
          });
        });
    },
    async create(payload) {
      var vx = this;

      await PerujukRepository.createPerujuks(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.$emit("event", resolve);
          }).then(response => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Penambahan data Perujuk berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function() {
                blockUI.unblockModal();
                vx.clearData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Penambahan data Perujuk gagal disimpan.",
            swalType: "error",
            onConfirmButton: function() {
              blockUI.unblockModal();
            }
          });
        });
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
    if(this.modalData){
        this.updateData(this.modalData);
    } else {
        this.constructData();
    }
  }
};
</script>

<style>
</style>
