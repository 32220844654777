<template>
    <div>
        <div class="row" style="margin-bottom: 1rem">
            <div class="col-5">
                <select
                    v-model="data.tipe"
                    class="form-control"
                    v-validate="'required'"
                    :name="'selectTipealamat'+index"
                    data-vv-as="Tipe Alamat"
                    :class="{'is-invalid': errors.has('selectTipealamat'+index) }"
                >
                    <option :value="null" disabled selected >Pilih Tipe Alamat</option>
                    <option :value="TipeAlamat.KLINIK">Klinik</option>
                    <option :value="TipeAlamat.RUMAH_SAKIT">Rumah Sakit</option>
                    <option :value="TipeAlamat.RUMAH_BERSALIN">Rumah Bersalin</option>
                    <option :value="TipeAlamat.PRAKTEK_PRIBADI">Praktek Pribadi</option>
                    <option :value="TipeAlamat.KERJA">Kantor</option>
                    <option :value="TipeAlamat.RUMAH">Rumah</option>
                </select>
                <div v-show="errors.first('selectTipealamat'+index)" class="invalid-feedback">{{ errors.first('selectTipealamat'+index) }}</div>
            </div>
            <div class="col-5">
                <input
                    placeholder="Nama Alamat"
                    v-model="data.nama"
                    type="text"
                    class="form-control"
                    v-validate="'required'"
                    :name="'namaALamat'+index"
                    data-vv-as="Nama Alamat"
                    :class="{'is-invalid': errors.has('namaALamat'+index) }"
                    >
                <div v-show="errors.first('namaALamat'+index)" class="invalid-feedback">{{ errors.first('namaALamat'+index) }}</div>
            </div>
            <div class="col-2">
                <button type="button" class="btn btn-elevate-hover btn-sm btn-font-danger" @click="deleteComponent">
                    <i class="la la-close"></i>
                </button>
            </div>
        </div>
        <div class="row" style="margin-bottom: 1rem">
            <div class="col-10">
                <textarea
                    placeholder="Nama Jalan"
                    v-model="data.jalan"
                    class="form-control"
                    v-validate="'required'"
                    :name="'jalan'+index"
                    data-vv-as="Jalan"
                    :class="{'is-invalid': errors.has('jalan'+index) }"
                ></textarea>
                <div v-show="errors.first('jalan'+index)" class="invalid-feedback">{{ errors.first('jalan'+index) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import TipeAlamat from "./../../../constants/tipe-alamat-enum";

export default {
    inject: ["$validator"],
    components:{

    },
    props:{
        data: { type: Object },
        index: {type: Number}
    },
    data() {
        return {
            TipeAlamat: TipeAlamat
        }
    },
    methods: {
        deleteComponent: function(){
            this.$emit('delalamat', this.index )
        }
    },
}
</script>

<style>

</style>
